import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionHeading,
  SectionSingleProduct
} from "../styledComponents/section"
import ProductList from "../components/ProductList"
import SEO from "../components/seo"
import SingleProduct from "../components/Products/SingleProduct"
import SingleProductBook from "../components/Products/SingleProductBook"
import PermissioningProductPromo from "../components//Products/PermissioningProductPromo"
import { StyledProductListHeader } from "../components/Products/productArchive"
import { PromoPatSection } from "../styledComponents/promoPatSection"
import SelfHealingMasteryFlashSale from "../components/Offer/SelfHealingMasteryFlashSale"
import { EventListSection } from "../styledComponents/eventList"
import LayoutNoStripe from "../components/layoutNoStripe"

const Heading = ({ level = 1, location, children, offScreen }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen}>
    {children}
  </SectionHeading>
)


const ShopPostTemplate = ({
  data: {
    product,
    allWpProductList,
    fullPrice,
    fullPriceUsd,
    salePrice,
    salePriceUsd,
    hidesale,
    patPrograms,
    allWpQtPendantProducts,
    allWpAudioProducts,
    allWpOtherProducts
  }, location
}) => {
  const seoExcerpt = product.excerpt.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '')
  const [countryCode, setCountryCode] = useState("") // store token
  const [countryURL, setCountryURL] = useState("");
  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if(urlParams.has("usd")){
      setCountryURL('usd')
    }
  }
  let allproducts
  let otherproducts
  let headerValue
  let otherProductsHeaderValue
  if (product.productsTag?.nodes[0]?.slug === 'audio-meditation') {
    allproducts = allWpAudioProducts.edges
    otherproducts = allWpOtherProducts.edges
    headerValue = 'Other Audio Meditations'
    otherProductsHeaderValue = 'Other Programs and Jewellery'
  }
  else if (product.productsTag?.nodes[0]?.slug === 'pendant-mala') {
    allproducts = allWpQtPendantProducts.edges
    otherproducts = allWpAudioProducts.edges
    headerValue = 'Other Pendants & Malas'
    otherProductsHeaderValue = 'Audio Meditations'
  }
  else {
    allproducts = allWpOtherProducts.edges
  }

  useEffect(() => {
    fetch('/geolocation', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (geoLocationData) {
        setCountryCode(geoLocationData?.geo?.country?.code)
      })
      checkIfHasQuery()
  }, [])
  return (
    <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp >
      <SEO
        title={product.title}
        description={seoExcerpt}
        url={product.uri}
        image={product.featuredImage.node.localFile.publicURL}
      />
      <SectionSingleProduct>
        {
          product.productsTag?.nodes[0]?.name === 'Book' ?
            <SingleProductBook
              title={product.title}
              uri={product.uri}
              excerpt={product.excerpt}
              featuredImage={product.featuredImage}
              content={product.content}
              amazonLink={product.productData.linkToAmazon}
              reviewCount={product.productData.reviewcount}
              ratingValue={product.productData.ratingvalue}
            />
            :
            <SingleProduct
              title={product.title}
              uri={product.uri}
              excerpt={product.excerpt}
              featuredImage={product.featuredImage}
              content={product.content}
              sale={product.productData.pricesale}
              fullPrice={countryCode === 'US' || countryURL === 'usd' ? fullPriceUsd : fullPrice}
              salePrice={countryCode === 'US' || countryURL === 'usd'? salePriceUsd : salePrice}
              hidesale={product.productData.hidesale}
              currency={countryCode === 'US' || countryURL === 'usd' ? '$' : '£'}
              sku={countryCode === 'US' || countryURL === 'usd' ? fullPriceUsd : fullPrice}
              id={product.id}
              tag={product.productsTag}
              stripePaymentLink={countryCode === 'US' || countryURL === 'usd' ? product.productData.stripeurlusd : product.productData.stripeurlgbp}
              testimonials={product.testimonials}
              reviewCount={product.productData.reviewcount}
              ratingValue={product.productData.ratingvalue}
              priceFullValue={countryCode === 'US' || countryURL === 'usd' ? product.productData.pricefullusd : product.productData.pricefull}
            />
        }
      </SectionSingleProduct>
      <Section>
        <Heading level={1} className="hidden"> {headerValue} </Heading>
        <EventListSection className="event-list">
          <ProductList posts={allproducts} />
        </EventListSection>
        {otherproducts &&
          <>
            <Heading level={1} className="hidden"> {otherProductsHeaderValue} </Heading>
            <EventListSection className="event-list">
              <ProductList posts={otherproducts} />
            </EventListSection>
          </>
        }
      </Section>
    </LayoutNoStripe>
  )
}

export default ShopPostTemplate

export const pageQuery = graphql`
  query ShopPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $stripefullprice: String!
    $stripesaleprice: String!
    $stripefullpriceusd: String!
    $stripesalepriceusd: String!
  ) {
    # selecting the current post by id
    product: wpProduct(id: { eq: $id }) {
      id
      title
      content
      uri
      excerpt
      testimonials {
        blockquote {
          citationName
          quote
        }
      }
      productData {
        linkToAmazon
        pricefull
        pricesale
        stripefullprice
        stripesaleprice
        pricefullusd
        pricesaleusd
        stripefullpriceusd
        stripesalepriceusd
        stripeurlgbp
        stripeurlusd
        hidesale
        reviewcount
        ratingvalue
      }
      productsTag {
            nodes {
              name
              slug
            }
          }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 975
                quality: 100
                placeholder: TRACED_SVG
                breakpoints: [320, 720, 1024, 1600]
              )
            }
            publicURL
          }
        }
      }
    }

    fullPrice: allStripePrice(filter: {active: {eq: true}, id: {eq: $stripefullprice}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    fullPriceUsd: allStripePrice(filter: {active: {eq: true}, id: {eq: $stripefullpriceusd}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePrice: allStripePrice(filter: {active: {eq: true}, id: {eq: $stripesaleprice}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePriceUsd: allStripePrice(filter: {active: {eq: true}, id: {eq: $stripesalepriceusd}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }
    
    allWpProductList: allWpProduct(filter: {id: {ne: $id},productsTag: {nodes: {elemMatch: {slug: {ne: "book"}}}}}) {
      edges {
        node {
          title
          uri
          id
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            stripeurlgbp
            stripeurlusd
            hidesale
            hideonarchive
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    patPrograms:   allWpProduct(filter: {productsTag: {nodes: {elemMatch: {name: {eq: "sleep-anxiety"}}}}}) {
      edges {
        node {
          title
          uri
          id
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            stripeurlgbp
            stripeurlusd
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    },
    
    allWpQtPendantProducts:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {id: {ne: $id},title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "pendant-mala"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }, 
    
    allWpAudioProducts:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {id: {ne: $id},title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "audio-meditation"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }, 
    allWpOtherProducts:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {id: {ne: $id},title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {ne: "audio-meditation"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    },
  }
`
